var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"breadcrumb-top"},[_c('b-breadcrumb',{},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"16"}})],1),_c('b-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.$t('Configuration'))+" ")]),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'service-route' }}},[_vm._v(" "+_vm._s(_vm.$t('Service Route'))+" ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" "+_vm._s(_vm.$t('Service Route'))+" ")])],1)],1),_c('b-button',{staticClass:"ll-cancel",attrs:{"variant":"flat-secondary","to":{ name: 'service-route' }}},[_c('b-img',{staticClass:"mr-1",attrs:{"src":require('@/assets/images/imgs/return.png'),"height":"auto"}})],1),_c('b-card',[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('Service route table')))])],1),_c('b-card-body',[_c('b-button',{staticClass:"ll-addbtn",attrs:{"variant":"info"},on:{"click":function($event){return _vm.insertEvent()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('Add')))])]),_c('vxe-table',{ref:"xTable",attrs:{"border":"","show-overflow":"","keep-source":"","max-height":"400","data":_vm.items,"edit-config":{trigger: 'click', mode: 'row', showStatus: true},"empty-text":"No matching records found"}},[_c('vxe-table-column',{attrs:{"field":"merchantCode","title":_vm.$t('Company')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.merchantName),callback:function ($$v) {_vm.$set(row, "merchantName", $$v)},expression:"row.merchantName"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"service","title":_vm.$t('Service')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.merchantName),callback:function ($$v) {_vm.$set(row, "merchantName", $$v)},expression:"row.merchantName"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"priority","title":_vm.$t('Priority')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.merchantName),callback:function ($$v) {_vm.$set(row, "merchantName", $$v)},expression:"row.merchantName"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"status","title":_vm.$t('Status No')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-input',{model:{value:(row.merchantName),callback:function ($$v) {_vm.$set(row, "merchantName", $$v)},expression:"row.merchantName"}})]}}])}),_c('vxe-table-column',{attrs:{"field":"createdAt","title":_vm.$t('Creation Time')}}),_c('vxe-table-column',{attrs:{"field":"isEnabled","title":_vm.$t('Active')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-form-checkbox',{class:row.isEnabled ? 'custom-control-info': 'custom-control-secondary',model:{value:(row.isEnabled),callback:function ($$v) {_vm.$set(row, "isEnabled", $$v)},expression:"row.isEnabled"}})]}}])}),_c('vxe-table-column',{attrs:{"title":_vm.$t('Action'),"width":"100","show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-button',{attrs:{"type":"text","icon":""},on:{"click":function($event){return _vm.removeEvent(row)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)]}}])})],1)],1)],1),_c('div',{staticClass:"ll-subBtn"},[_c('b-button',{attrs:{"variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }