<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Configuration') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'service-route' }" >
          {{ $t('Service Route') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Create') }} {{ $t('Service Route') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'service-route' }"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    <!-- content -->
    <b-card>
      <b-card-header>
        <b-card-title>{{ $t('Service route table') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-button
          class="ll-addbtn"
          variant="info"
          @click="insertEvent()"
        >
          <span>{{ $t('Add') }}</span>
        </b-button>
        <vxe-table
          border
          show-overflow
          keep-source
          ref="xTable"
          max-height="400"
          :data="items"
          :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
          empty-text="No matching records found"
        >
          <vxe-table-column field="merchantCode" :title="$t('Company')" >
            <template #default="{ row }">
              <b-form-input v-model="row.merchantName"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="service" :title="$t('Service')" >
            <template #default="{ row }">
              <b-form-input v-model="row.merchantName"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="priority" :title="$t('Priority')" >
            <template #default="{ row }">
              <b-form-input v-model="row.merchantName"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="status" :title="$t('Status No')" >
            <template #default="{ row }">
              <b-form-input v-model="row.merchantName"/>
            </template>
          </vxe-table-column>
          <vxe-table-column field="createdAt" :title="$t('Creation Time')"  >
          </vxe-table-column>
          <vxe-table-column field="isEnabled" :title="$t('Active')" >
            <template #default="{ row }">
              <b-form-checkbox
                v-model="row.isEnabled"
                :class="row.isEnabled ? 'custom-control-info': 'custom-control-secondary'"
              >
              </b-form-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column :title="$t('Action')" width="100" show-overflow>
          <template #default="{ row }" >
            <vxe-button type="text" icon="" @click="removeEvent(row)">
              <feather-icon icon="Trash2Icon" />
            </vxe-button>
          </template>
        </vxe-table-column>
        </vxe-table>
      </b-card-body>
    </b-card>

    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click.prevent="submit"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import {
  BBreadcrumb,BBreadcrumbItem, BButton, BImg,
  BRow, BCol,
  BCard, BCardHeader, BCardTitle, BCardBody,
  BFormGroup, BInputGroup, BFormInput, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    required,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BInputGroup,
    BFormInput,
    flatPickr,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
  },
  data() {
    return {
      items: [],
    }
  },
  created() {},
  mounted() {
  },
  methods: {
    submit() {},
    async insertEvent (row) {
      const $table = this.$refs.xTable
      const record = {}
      const { row: newRow } = await $table.insertAt(record, row)
      await $table.setActiveCell(newRow)
    },
    removeEvent (row) {
      this.box = ''
      this.$bvModal
      .msgBoxConfirm('Please confirm that you want to delete .', {
        title: 'Handle',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if (value) {
          this.box = value
          this.$refs.xTable.remove(row)
        }
      })
    },
  },
}
</script>
<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-addbtn{
  margin-bottom: 10px;
}
</style>